const $ = require('jquery');
/**
 * Navigation interactions
 */

function isXSBreakpoint() {
  return $('#js-xs-breakpoint-check').width() !== 0;
}

// When leaving the navigation area, close the navigation
$('.navigation a, .navigation button').on('focusout', (e) => {
  if (isXSBreakpoint() && e.relatedTarget &&
      $(e.relatedTarget).closest('.navigation').length === 0) {
    $('body').removeClass('nav-open');
  }
});

// When entering the navigation area, open the navigation
$('.navigation a, .navigation button').on('focus', () => {
  if (isXSBreakpoint()) {
    $('body').addClass('nav-open');
  }
});

// Close the navigation area when skipping to content area
$('.skip-to-main-content').on('click', () => {
  if (isXSBreakpoint()) {
    $('body').removeClass('nav-open');
  }
});

$('nav .menu-item-close').on('click', () => {
  if (isXSBreakpoint()) {
    $('body').removeClass('nav-open');
  }
});

$('nav .burger').on('click', () => {
  if (isXSBreakpoint()) {
    $('body').addClass('nav-open');
    $('nav .menu-item-skip a').focus();
  }
});

/**
 * Search interactions
 */
$('.cancel-button').on('click', () => {
  $('.site-header').toggleClass('search-mode');
  $('#search-button').focus();
});

$('#search-button').on('click', () => {
  $('.site-header').toggleClass('search-mode');
  $('.search-field').focus();
});

$('.search-field').on('focus', () => {
  $('.search-field').attr('placeholder', 'Search for topics');
});

$('.search-field').on('blur', () => {
  $('.search-field').attr('placeholder', 'Search');
});
